<script setup lang="ts">
const route = useRoute()
const { search: meilisearchCar, result: meilisearchCarResult } = useMeiliSearch('descriptions')

const { $createLenisForElement } = useNuxtApp()

const scrollContainer = ref<HTMLElement | null>(null)
const chatLenis = ref<Lenis | null>(null)
const messageText = ref('')
const chatArr = ref<ChatMessage[]>([])

onMounted(() => {
  chatLenis.value = $createLenisForElement(scrollContainer.value as HTMLElement)
  scrollToBottom()
})

const scrollToBottom = () => {
  chatLenis.value?.scrollTo(document.body.scrollHeight)
}

const searchCar = async (car: ChatCar) => {
  console.log(`Search: ${car.mark} ${car.model} ${car.generation} ${car.modification}`)
  await meilisearchCar(`${car.mark} ${car.model} ${car.generation} ${car.modification}`, {
    limit: 1,
  })

  // TODO: сделать проверку на существование
  console.log(meilisearchCarResult.value!.hits[0])

  const { mark, model, name, generation, modification, configuration } = meilisearchCarResult.value!.hits[0]

  // Ищем машины в базе
  const result: DBCar = {
    name,
    slug: `/catalog/${mark}/${model}/${generation}/${configuration}/${modification}/`,
  }
  return result
}

const sendMessage = async (text: string) => {
  if (text.trim() !== '') {
    messageText.value = ''

    chatArr.value.push({
      content: text,
      role: 'user',
    })

    const message = chatArr.value

    console.log(text)
    const { data, error } = await useFetch('/api/chat', {
      method: 'POST',
      body: {
        message: chatArr.value
          .filter(m => !m.local)
          .map(m => {
            return {
              role: m.role,
              content: m.content,
            }
          }),
      },
      watch: false,
    })

    console.log(data.value)

    if (data.value && 'choice' in data.value) {
      const choice = data.value.choice
      console.log(choice)

      let choiceObj: ChatChoiceObj

      if (choice.message.content) {
        choice.message.content = choice.message.content.replace(/```json/g, '').replace(/```/g, '')
      }

      try {
        console.log(choice.message.content)

        choiceObj = JSON.parse(choice.message.content!)

        console.log(choiceObj)

        chatArr.value.push({
          content: choiceObj.summary,
          role: 'assistant',
          local: true,
        })

        chatArr.value.push({
          content: choice.message.content!,
          role: 'assistant',
        })

        let chatCarMessage: string = ''
        const chatCars: DBCar[] = []

        for (let i = 0; i < choiceObj.cars.slice(0, 3).length; i++) {
          const car = choiceObj.cars[i]
          const chatCar = await searchCar(car)
          chatCars.push(chatCar)

          chatCarMessage += `<b>${car.mark} ${car.model} ${car.generation ? car.generation : ''} ${car.modification ? car.modification : ''}</b><br />`
          chatCarMessage += `${car.description}`

          if (choiceObj.cars.length !== i - 1) {
            chatCarMessage += '<br /><br />'
          }
        }

        chatArr.value.push({
          content: chatCarMessage!,
          cars: chatCars,
          role: 'site',
          local: true,
        })
      } catch (error) {
        chatArr.value.push({
          content: 'Ошибка получения данных :(',
          role: 'assistant',
          local: true,
        })
      }
    } else {
      // TODO: Нужен обработчик ошибки
      console.log('choice не найдено')
    }

    nextTick(() => {
      chatLenis.value?.resize()
      scrollToBottom()
    })
  }
}
</script>

<template>
  <div class="h-screen">
    <div class="grid h-screen grid-cols-2">
      <div class="flex h-screen items-center justify-center">
        <div class="max-w-[652px]">
          <div class="mb-[96px]">
            <h1 class="text-text-secondary text-h48 mb-6 font-bold">Найдите автомобиль для&nbsp;ваших целей</h1>
            <p class="text-text-tertiary text-lg">
              В нашем сервисе мы используем абсолютно другой подход к поиску автомобилей. Задайте свой запрос и мы поможем вам выбрать
              автомобиль под конкретную задачу.
            </p>
            <p class="text-text-primary text-lg font-bold">Например: надежный автомобиль для поездок на дачу, до 1 млн рублей.</p>
          </div>
          <div class="bg-surface-brand relative rounded-3xl p-8 text-white">
            <NuxtImg src="images/car-main.png" densities="x1" format="webp" class="absolute -bottom-[28%] right-0" />
            <h2 class="mb-3 text-xl font-bold">Вы также можете воспользоваться нашим каталогом с уникальными фильтрами</h2>
            <p class="mb-8 text-lg opacity-80">
              В каталоге вы можете найти большое количество автомобилей, тщательно отобранные искусственным интеллектом, который мы
              настраивали на определенные задачи и запросы от реальных пользователей.
            </p>
            <Button linkTo="/catalog" size="xs" style-btn="primary-invert">Каталог автомобилей</Button>
          </div>
        </div>
      </div>
      <div class="bg-surface-primary flex h-screen items-end justify-center pb-16">
        <div class="w-full max-w-[628px]">
          <div class="flex h-[calc(100vh-122px)] flex-col justify-end pb-6">
            <div ref="scrollContainer" v-auto-animate class="grid gap-6 overflow-y-scroll pt-6 scrollbar-hide">
              <template v-for="message in chatArr">
                <div v-if="message.role === 'user'" class="ml-auto flex w-full max-w-[468px] items-end justify-end">
                  <div class="bg-surface-block w-full max-w-[422px] rounded-t-lg rounded-bl-lg p-3">
                    <p class="text-text-secondary text-base">
                      {{ message.content }}
                    </p>
                  </div>
                  <div class="ml-2 shrink-0">
                    <NuxtImg src="images/avatar-user.jpg" height="38" width="38" densities="x1" format="webp" class="w-full rounded-full" />
                  </div>
                </div>

                <div v-else-if="message.role === 'assistant' && message.local" class="ml-auto flex w-full items-end justify-start">
                  <div class="mr-2 shrink-0">
                    <NuxtImg src="images/avatar-ai.jpg" height="38" width="38" densities="x1" format="webp" class="w-full rounded-full" />
                  </div>
                  <div class="bg-surface-block w-full rounded-t-lg rounded-br-lg p-3">
                    <p class="text-text-secondary text-base">
                      {{ message.content }}
                    </p>
                  </div>
                </div>

                <div v-else-if="message.role === 'site'" class="ml-auto flex w-full items-end justify-start">
                  <div class="mr-2 shrink-0">
                    <NuxtImg src="images/avatar-ai.jpg" height="38" width="38" densities="x1" format="webp" class="w-full rounded-full" />
                  </div>
                  <div class="bg-surface-block w-full rounded-t-lg rounded-br-lg p-3">
                    <p class="text-text-secondary pb-6 text-base" v-html="message.content"></p>
                    <div class="flex gap-2 pb-4">
                      <NuxtLink v-for="car in message.cars" :to="car.slug" class="bg-surface-secondary max-w-1/3 w-full rounded-md p-3">
                        <p class="text-text-primary text-base font-bold">{{ car.name }}</p>
                      </NuxtLink>
                    </div>
                    <Button size="xs" styleBtn="secondary" class="w-full" @click="sendMessage('Другие варианты.')">Другие варианты</Button>
                  </div>
                </div>
              </template>
            </div>
          </div>
          <div class="flex items-end gap-4">
            <DynamicTextarea
              v-model="messageText"
              class="w-full"
              size="lg"
              placeholder="Введите ваш запрос"
              @keydown.enter.prevent.capture="sendMessage(messageText)"
            />
            <Button iconType="only" size="lg" style-btn="primary" iconName="uil:message" @click="sendMessage(messageText)" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
